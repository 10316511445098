import { api } from '../../../api';

export const getRewardPointsAPI = () => {
  return api.get('/user/mylist-credits/total');
};

export const getTransactionsAPI = () => {
  return api.get('/user/mylist-credits');
};

export const getConvertAPI = userData => {
  const { giftcard_number } = userData;
  return api.get('/user/get_giftcard_converted_limit', {
    params: {
      giftcard_number,
    },
  });
};

export const getRemainingAPI = userData => {
  const { giftcard_number } = userData;
  return api.get(`/giftcards/${giftcard_number}/balance`);
};

export const getConvertCreditsAPI = userData => {
  return api.post(`/giftcards/add_mylist_card`, userData);
};
