import React, { useEffect, useMemo } from 'react';
import './components/Cart.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import CartWidget from './components/CartWidget';
import CartItemsContainer from './components/CartItemsContainer';
import EmptyCartImage from '../../assets/media/icons/empty-state-cart.svg';
import { ROUTES } from '../../routes';
import { useAuth } from '../auth/hooks/useAuth';
import { useCart } from './hooks/useCart';
import { useGiftcards } from '../cards/hooks/useGiftcards';
import { useOrders } from '../orders/hooks/useOrders';
import { useRewardPoints } from '../rewardPoints/hooks/useRewardPoints';
import { useTopBar } from '../topBar/hooks/useTopBar';
import { useTranslation } from 'react-i18next';
import { useCookie } from "../../utils/useCookie";

function Cart() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAuthenticated, ...authState } = useAuth();
  const { onCreateOrderAction, ...orderState } = useOrders();
  const { onGetPaymentCurrencyAction, onGetFixerConversionRateAction } = useCart();
  const {
    onSaveItemsToCart,
    onUpdateTotalCartItems,
    onUpdateCheckout,
    onFetchItemsByCartAction,
    onAddRemoveQuantityAction,
    ...cartState
  } = useCart();
  const { onGetRewardPointsAction, ...rewardState } = useRewardPoints();
  const giftCardState = useGiftcards();
  const topBarState = useTopBar();
  const { getCookie } = useCookie()

  const selectedCountry = useMemo(() => giftCardState?.selectedCountry, [giftCardState]);
  const selectedCurrency = useMemo(() => cartState?.selectedCartCurrency?.unit_name_short, [cartState]);

  useEffect(() => {
    const total_cards = cartState.lineItems.length
    onUpdateTotalCartItems(total_cards)
  }, [cartState])

  useEffect(() => {
    onGetPaymentCurrencyAction();
    if (isAuthenticated) {
      onGetRewardPointsAction();
    }
  }, [onGetPaymentCurrencyAction, onGetRewardPointsAction]);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchItemsByCartAction({
        currency: selectedCurrency || 'AED',
        currency_id: cartState?.selectedCurrency?.id || 1,
      });
    }
  }, [onFetchItemsByCartAction]);

  useEffect(() => {
    if (orderState.error || orderState.order_checkout_error) {
      history.push({ pathname: ROUTES.failure });
    }
  }, [orderState.order_checkout_error, orderState.error, history]);

  const handleChangeCurrency = async event => {
    const currency = JSON.parse(event);
    if (currency) {
      if (isAuthenticated) {
        onFetchItemsByCartAction({
          currency: selectedCurrency?.unit_name_short || selectedCurrency || 'AED',
          currency_id: currency?.id || 1,
        });
      }
      onGetFixerConversionRateAction(currency);
    }
    // dispatch(getFixerConversionRateAction(selectedCurrency));
  };

  const removeItem = item => {
    if (isAuthenticated) {
      onAddRemoveQuantityAction({
        ...item,
        country_unit_short_name: cartState?.selectedCartCurrency?.unit_name_short || 'AED',
        country_id: cartState?.selectedCartCurrency?.id,
        action: 'CLEAR',
        country: selectedCountry,
      });
    } else {
      const lineItems = cartState.lineItems.filter(
        lineItem => !(lineItem.brand_id === item.brand_id && lineItem.giftcard_value === item.giftcard_value)
      );
      onSaveItemsToCart(lineItems);
      if (lineItems.length) {
        const totalCartItems = lineItems
          .map(lineItem => parseFloat(lineItem.quantity))
          .reduce((accumulator, currentValue) => accumulator + currentValue);
        onUpdateTotalCartItems(totalCartItems);
      } else {
        onUpdateTotalCartItems(0);
      }
    }
  };

  const incrementQuantity = item => {
    if (isAuthenticated) {
      onAddRemoveQuantityAction({
        ...item,
        country_unit_short_name: cartState?.selectedCartCurrency?.unit_name_short || 'AED',
        country_id: cartState?.selectedCartCurrency?.id,
        action: 'ADD',
        country: selectedCountry,
      });
    } else {
      const lineItems = cartState.lineItems.map(lineItem => {
        if (lineItem.brand_id === item.brand_id && lineItem.giftcard_value === item.giftcard_value) {
          return Object.assign({}, lineItem, {
            quantity: parseFloat(lineItem.quantity) + 1,
          });
        }
        return lineItem;
      });
      onSaveItemsToCart(lineItems);
      if (lineItems.length) {
        const totalCartItems = lineItems
          .map(lineItem => parseFloat(lineItem.quantity))
          .reduce((accumulator, currentValue) => accumulator + currentValue);
        onUpdateTotalCartItems(totalCartItems);
      } else {
        onUpdateTotalCartItems(0);
      }
    }
  };

  const decrementQuantity = item => {
    if (isAuthenticated) {
      if (item.quantity === 1) {
        removeItem(item);
      } else {
        onAddRemoveQuantityAction({
          ...item,
          country_unit_short_name: cartState?.selectedCartCurrency?.unit_name_short || 'AED',
          country_id: cartState?.selectedCartCurrency?.id,
          action: 'REMOVE',
          country: selectedCountry,
        });
      }
    } else {
      if (item.quantity === 1) {
        removeItem(item);
      } else {
        const lineItems = cartState.lineItems.map(lineItem => {
          if (lineItem.brand_id === item.brand_id && lineItem.giftcard_value === item.giftcard_value) {
            return Object.assign({}, lineItem, {
              quantity: parseFloat(lineItem.quantity) - 1,
            });
          }
          return lineItem;
        });
        onSaveItemsToCart(lineItems);
        if (lineItems.length) {
          const totalCartItems = lineItems
            .map(lineItem => parseFloat(lineItem.quantity))
            .reduce((accumulator, currentValue) => accumulator + currentValue);
          onUpdateTotalCartItems(totalCartItems);
        } else {
          onUpdateTotalCartItems(0);
        }
      }
    }
  };

  const createCheckout = async data => {
    if (data?.are_reward_points_used && !data?.isDiffAmountToBePaid) {
      const tduid = getCookie('TRADEDOUBLER')

      const payload = {
        orders: {
          card_value_aed: null,
          order_total_aed: data.total_amount,
          program_id: 1,
          use_credits: data.are_reward_points_used,
          current_exchange_rate: cartState.conversion.currency_exchange_rate,
          use_hassad_points: data.are_reward_points_used,
          language_code: 'en',
          isbuynow: false,
          isforself: 1,
          payment_currency: data.currency || 'AED',
          currency: data.currency_id,
          used_credits: data.total_amount_aed,
          is_myworld: !!tduid
        },
      };
      onCreateOrderAction({ data: payload, event: {} });
    } else {
      onUpdateCheckout(data);
      history.push(ROUTES.payment);
    }
  };

  const getCartContent = () => {
    if (cartState.lineItems.length) {
      return (
        <Row className="my-5">
          <Col md={5}>
            <CartWidget
              state={cartState}
              authState={{ ...authState, isAuthenticated }}
              rewardState={rewardState}
              giftunitState={giftCardState}
              handleChangeCurrency={handleChangeCurrency}
              history={history}
              createCheckout={createCheckout}
            />
          </Col>

          <Col md={7}>
            <CartItemsContainer
              cartState={cartState}
              giftCardState={giftCardState}
              history={history}
              removeItem={removeItem}
              incrementQuantity={incrementQuantity}
              decrementQuantity={decrementQuantity}
              topbarState={topBarState}
            />
          </Col>
        </Row>
      );
    }
    return (
      <>
        <Row>
          <Col xs={12} className="text-center">
            <img src={EmptyCartImage} className="img-fluid empty-cart-image" alt="Empty cart" />
          </Col>
          <Col xs={12} className="text-center pt-4">
            <h1 className="empty-cart-header">{t('cart.emptyScreen.title')}!</h1>
          </Col>
          <Col xs={12} className="text-center pt-2">
            <h4 className="empty-cart-subheader">{t('cart.emptyScreen.subtitle')}?</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ offset: 4, span: 4 }} className="pt-4 d-grid gap-2">
            <Button
              className="btn-block start-gifting-btn"
              variant="info"
              type="button"
              onClick={() => history.push({ pathname: ROUTES.home })}
            >
              {t('cart.emptyScreen.button')}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Container
        fluid={cartState.lineItems.length}
        className={`${!cartState.lineItems.length ? 'empty-cart-container' : ''}`}
      >
        {getCartContent()}
      </Container>
    </>
  );
}

export default Cart;
