import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ConditionsListItem = ({ text, isLast, isSublistItem = false, withDangerouslySetInnerHTML }) => {
  const { t } = useTranslation('conditions');

  const style = isLast ? { marginBottom: '2rem' } : {};

  const ItemTag = isSublistItem ? 'li' : 'p';

  return withDangerouslySetInnerHTML ? (
    <ItemTag
      style={style}
      dangerouslySetInnerHTML={{
        __html: t(text, { interpolation: { escapeValue: false } }),
      }}
    />
  ) : (
    <ItemTag style={style}>{t(text)}</ItemTag>
  );
};

ConditionsListItem.propTypes = {
  text: PropTypes.string,
  isLast: PropTypes.bool,
  isSublistItem: PropTypes.bool,
  withDangerouslySetInnerHTML: PropTypes.bool,
};
