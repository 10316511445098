import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Dropdown } from 'primereact/dropdown';

import { useAuth } from '../../features/auth/hooks/useAuth';
import { useGiftcards } from '../../features/cards/hooks/useGiftcards';
import { useCart } from '../../features/cart/hooks/useCart';
import { useProfile } from '../../features/profile/hooks/useProfile';
import { useTopBar } from '../../features/topBar/hooks/useTopBar';
import { useCategory } from '../../features/category/hooks/useCategory';
import { ProfileDropdown } from './ProfileDropdown';
import { ROUTES } from '../../routes';
import { Link } from '../link';

import userIcon from '../../assets/media/icons/uprofile.svg';
import coinsIcon from '../../assets/media/icons/coins.svg';
import exitIcon from '../../assets/media/icons/exit.svg';
import cartIcon from '../../assets/media/icons/cart.svg';
import Logo from '../../assets/media/icons/logo.svg';
import GiftiIcon from '../../assets/media/icons/gifti-icon.svg';
import Shoppingcart from '../../assets/media/icons/blue-cart.svg';
import UserLogin from '../../assets/media/icons/user-auth.svg';
import Location from '../../assets/media/icons/location.svg';

import './index.scss';
import { useRewardPoints } from '../../features/rewardPoints/hooks/useRewardPoints';
import config from '../../config';

//Countries are comming from giftunit
const GiftiNav = () => {
  const { onLogout } = useAuth();
  const { onGetProfileListAction } = useProfile();
  const { onGetCountriesListAction } = useTopBar();
  const { pathname } = useLocation();
  const isLogin = useMemo(() => pathname === ROUTES.auth.logIn, [pathname]);

  // todo: refactor to another logic
  const authState = useAuth();
  //giftcardsState
  const { onSelectCountry, ...state } = useGiftcards();
  const { onCartTotalCountAction, onFetchItemsByCartAction, onBulkUpdateCartAction, ...cartState } = useCart();
  const { onSetCategoryId } = useCategory();
  // todo: need to be changed
  const { countries, selectedCountry } = state;
  const topBarState = useTopBar();
  const token = localStorage.getItem('access_token');

  const [isTotalCartCountActionCalled, setIsTotalCartCountActionCalled] = useState(false);

  const { t } = useTranslation('main');
  const { isAuthenticated, alertlogin, signupOrLoginActionClicked } = useAuth();

  const { profile } = useProfile();
  const { total_credits, onGetRewardPointsAction } = useRewardPoints();

  const clearsession = () => {
    triggerLogout();
  };

  const selectedCountryTemplate = useCallback((option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.country_name}</div>
        </div>
      );
    }
    // eslint-disable-next-line react/prop-types
    return <span>{props.placeholder}</span>;
  }, []);

  const countryOptionTemplate = useCallback(option => {
    return (
      <div className="country-item">
        <div>{option.country_name}</div>
      </div>
    );
  }, []);

  useEffect(() => {
    onGetCountriesListAction();
  }, [onGetCountriesListAction]);

  useEffect(() => {
    if (isAuthenticated) {
      onGetRewardPointsAction();
    }
  }, [onGetRewardPointsAction, isAuthenticated]);

  useEffect(() => {
    if (authState.isAuthenticated && !isTotalCartCountActionCalled) {
      setIsTotalCartCountActionCalled(true);
      onGetProfileListAction();
    }
  }, [
    onCartTotalCountAction,
    onGetProfileListAction,
    authState.isAuthenticated,
    isTotalCartCountActionCalled,
    setIsTotalCartCountActionCalled,
  ]);

  const handleCartUpdate = useCallback(async () => {
    if (isAuthenticated) {
      if (signupOrLoginActionClicked) {
        await onBulkUpdateCartAction()
      }
      // onFetchItemsByCartAction({
      //   currency: cartState?.selectedCartCurrency?.unit_name_short || 'AED',
      //   currency_id: cartState?.selectedCurrency?.id || 1,
      // });
    }
  }, [
    alertlogin,
    cartState?.selectedCartCurrency?.unit_name_short,
    cartState?.selectedCurrency?.id,
    isAuthenticated,
    onBulkUpdateCartAction,
    onFetchItemsByCartAction,
    signupOrLoginActionClicked
  ]);

  useEffect(() => {
    handleCartUpdate()
  }, [handleCartUpdate, isAuthenticated]);


  const handleCountryChange = e => {
    const value = e.value;
    const filteredId = topBarState.countries.filter(country => country.country_name === value.country_name);
    if (filteredId && filteredId.length) onSelectCountry({ ...value, country_id: filteredId[0].id });

    onSetCategoryId('ALL');
  };

  const triggerLogout = async () => {
    await onLogout(true);
    localStorage.removeItem('access_token');
    localStorage.removeItem('first_name');
    localStorage.removeItem('state');
    localStorage.clear();
    sessionStorage.clear();
    // window.location.reload();
  };

  return (
    <header
      className="navbar navbar-expand-md flex-column navbar-light bg-white d-print-none gifti-navbar pt-0 px-0 pb-0 position-sticky top-0"
      style={{ zIndex: 3 }}
    >
      <div className="above-header">
        <Container fluid className="flex-lg-wrap ps-0 pe-0 d-flex justify-content-end">
          {!token ? (
            <>
              <RouterLink to={ROUTES.auth.signUp}>{t('common.createAccount')}</RouterLink>
              <RouterLink target="_blank" to={{ pathname: config.rewardsByLandingUrl }} rel="noopener">
                {t('topBar.forBusiness')}
              </RouterLink>
            </>
          ) : total_credits ? (
            <a>Points: {total_credits}</a>
          ) : null}

          <RouterLink to={isAuthenticated ? ROUTES.rewardPoints : ROUTES.auth.logIn} className="me-0">
            {t('topBar.redeemYourGiftiGlobalCard')}
          </RouterLink>
        </Container>
      </div>

      <Container fluid className="header ps-0 pe-0 ms-0 me-0">
        <div className="d-flex align-items-center">
          <Navbar.Brand>
            <h1 className="navbar-brand logoIcon navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-lg-3">
              <RouterLink to={ROUTES.home}>
                <picture>
                  <source media="(max-width: 991px)" srcSet={GiftiIcon} width="60px" />
                  <img src={Logo} alt="GiftiGlobal" />
                </picture>
              </RouterLink>
            </h1>
          </Navbar.Brand>
          <Nav className="header-location" style={{ backgroundColor: 'rgba(255,255,255, 0)', border: 'none' }}>
            <div className="nav-item ms-lg-3">
              <p className="location-dropdown-text text-end">
                <small>{t('common.iAmGiftingTo')}</small>
              </p>
              <div className="location-dropdown d-flex">
                <img className="location-dropdown-icon" src={Location} alt="location" />
                <Dropdown
                  value={state.selectedCountry}
                  options={countries}
                  onChange={handleCountryChange}
                  filter
                  filterBy="country_name"
                  placeholder={state?.selectedCountry?.country_name || t('common.pleaseSelectCountry')}
                  optionLabel="country_name"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  style={{ overflow: 'auto', textOverflow: 'ellipsis' }}
                />
              </div>
            </div>
          </Nav>
        </div>

        <Nav
          className="align-items-center auth-cart-nav"
          style={{ backgroundColor: 'rgba(255,255,255, 0)', border: 'none' }}
        >
          <Nav.Item className="nav-auth">
            <div className="nav-item">
              {!token ? (
                <>
                  <Link
                    className="nav-btn cart btn-cart position-relative d-flex align-content-center border-0"
                    to={isLogin ? ROUTES.auth.signUp : ROUTES.auth.logIn}
                    variant="link"
                  >
                    <>
                      <p className="text-dark mb-0 navigation-login-text">
                        {isLogin ? t('auth.common.signUpText') : t('auth.common.signInText')}
                      </p>
                      <img src={UserLogin} alt="Icon" className="navigation-logIn-icon ms-1 ms-sm-2 ms-lg-3" />
                    </>
                  </Link>
                </>
              ) : (
                <ProfileDropdown
                  user={profile?.first_name || ''}
                  clearSession={clearsession}
                  profileIcon={userIcon}
                  coinsIcon={coinsIcon}
                  exitIcon={exitIcon}
                  cartIcon={cartIcon}
                />
              )}
            </div>
          </Nav.Item>
          <Nav.Item>
            <Link
              to={ROUTES.cart}
              className="nav-btn cart btn-cart position-relative d-flex align-content-center border-0"
              variant="link"
            >
              <>
                <img
                  className="navigation-cart-icon"
                  src={Shoppingcart}
                  alt="shoppingcart-icon"
                  width="25px"
                  height="25px"
                />
                {cartState.totalCartItems > 0 && (
                  <span className="cartBadge badge badge-danger" id="lblCartCount">
                    {cartState.totalCartItems}
                  </span>
                )}
                <p className="text-dark ml-2 mb-0 navigation-cart-text">{t('cart.cart')}</p>
              </>
            </Link>
          </Nav.Item>
        </Nav>
      </Container>
    </header>
  );
};

export default GiftiNav;
