import { api } from '../../../api';

export const allOrderApiCall = order => {
  const { image_size, limit, offset } = order;
  return api.get('/orders', {
    params: {
      image_size,
      limit,
      offset,
    },
  });
};

export const processOrderApiCall = order => {
  const { order_id } = order;
  return api.post(
    '/payments/process_order_after_redirect',
    {},
    {
      params: {
        order_id,
      },
    }
  );
};

export const orderDetailsApiCall = order => {
  const { order_id, image_size } = order;
  return api.get(`/orders/${order_id}`, { params: { image_size } });
};

export const failedOrderApiCall = order => {
  const { order_id } = order;
  return api.post(`/payments/failed_order_after_redirect`, order, { params: { order_id } });
};

export const createOrderAPI = order => {
  return api.post('/orders', order);
};

export const createOrderCheckoutAPI = payload => {
  return api.post(`/payments/create_checkout`, payload);
};

export const processOrderByGiftCardAPI = orderId => {
  const data = { order_id: `${orderId}` };
  return api.post(`/orders/process_order`, data);
};

export const createGuestOrderAPI = payload => {
  return api.post(`/guest_orders`, payload);
};
