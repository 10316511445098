import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { Loaders } from '../../../shared';
import { ROUTES } from '../../../routes';
import { useCookie } from '../../../utils/useCookie';
import { usePageLoader } from '../../../shared/loaders/pageLoader/hooks/usePageLoader';
import { useAuth } from '../../auth/hooks/useAuth';
import { useCart } from '../../cart/hooks/useCart';
import { useOrders } from '../../orders/hooks/useOrders';
import { useProfile } from '../../profile/hooks/useProfile';

import './Frame.scss';

const style = {
  base: {
    iconColor: '#c4f0ff',
    color: '#31325F',
    lineHeight: '40px',
    fontWeight: '200',
    fontFamily: 'monospace',
    fontSize: '17px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#31325F',
      backgroundColor: '#ffffff',
    },
    '::placeholder': {
      color: '#31325F',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif',
      fontWeight: '300',
    },
  },
};

const publicKey = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`;

const getUserBillingDetails = userState => {
  if (!userState) return undefined;

  const { first_name, last_name, email, phone } = userState;
  const name =
    first_name?.replace(' ', '') || last_name?.replace(' ', '')
      ? `${first_name.replace(' ', '')} ${last_name.replace(' ', '')}`
      : undefined;

  return {
    name,
    email,
    phone,
  };
};

const CheckoutForm = () => {
  const stripePromise = loadStripe(publicKey);
  const stripe = useStripe();
  const elements = useElements('card', { hidePostalCode: true });

  const cardNumberOptions = { placeholder: 'Card number', style: style };
  const cardExpiryOptions = { placeholder: 'MM/YY', style: style };
  const cardCVVOptions = { placeholder: 'CVV', style: style };

  const { onCreateOrderAction, onCreateGuestOrderAction, onSetOrdersLoading, ...orderState } = useOrders();
  const { checkoutCart, conversion } = useCart();
  const { onGetProfileListAction, ...profileState } = useProfile();
  const { onSetPageLoadingAction } = usePageLoader();
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const { getCookie } = useCookie();

  const [billingDetails, setBillingDetails] = useState(
    getUserBillingDetails(isAuthenticated ? profileState?.profile : orderState?.guest_payload?.user)
  );

  useEffect(() => {
    if (isAuthenticated) {
      onGetProfileListAction();
    }
  }, [onGetProfileListAction]);

  useEffect(() => {
    if (orderState.error || orderState.order_checkout_error) {
      history.push({ pathname: ROUTES.failure });
    }
  }, [orderState.order_checkout_error, orderState.error, history]);

  useEffect(() => {
    if (orderState?.pi_secret && orderState?.created_order?.id) {
      try {
        stripe.confirmCardPayment(orderState?.pi_secret).then(function (result) {
          if (result.error) {
            window.location.href = `${window.location.origin}/failure?order_id=${orderState?.created_order?.id}&pi_id=${orderState?.pi_id}`;
          } else {
            window.location.href = `${window.location.origin}/success?order_id=${orderState?.created_order?.id}&pi_id=${orderState?.pi_id}`;
          }
        });
      } catch (err) {
        orderState.pi_secret = null;
      }
    }
  }, [orderState?.pi_secret]);

  const [correctnessOfPaymentDetails, setCorrectnessOfPaymentDetails] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    name: Boolean(billingDetails?.name),
  });

  const isPaymentDetailsCorrect = useMemo(
    () =>
      !Object.keys(correctnessOfPaymentDetails).find(
        paymentDetailsItemKey => correctnessOfPaymentDetails[paymentDetailsItemKey] === false
      ),
    [correctnessOfPaymentDetails]
  );

  const updateCardPaymentDetailsCorrectness = useCallback(
    e => {
      document.getElementById('card-errors').textContent = '';
      correctnessOfPaymentDetails[e.elementType] = e.complete;
      setCorrectnessOfPaymentDetails({ ...correctnessOfPaymentDetails });
    },
    [setCorrectnessOfPaymentDetails, correctnessOfPaymentDetails]
  );

  const processOrder = event => {
    event['token'] = event.id;
    const tduid = getCookie('TRADEDOUBLER');

    if (isAuthenticated) {
      const payload = {
        orders: {
          card_value_aed: null,
          order_total_aed: checkoutCart.total_amount,
          program_id: 1,
          use_credits: checkoutCart.are_reward_points_used,
          current_exchange_rate: conversion.currency_exchange_rate,
          use_hassad_points: false,
          language_code: 'en',
          isbuynow: false,
          isforself: 1,
          payment_currency: checkoutCart.currency || 'AED',
          currency: checkoutCart.currency_id,
          is_myworld: !!tduid,
        },
      };
      if (checkoutCart.are_reward_points_used) {
        payload.orders['used_credits'] = checkoutCart?.used_credits || 0;
      }
      onCreateOrderAction({
        data: payload,
        event,
        amount_to_pay: checkoutCart.amount_to_pay,
      });
    } else {
      onCreateGuestOrderAction({
        data: orderState.guest_payload,
        event,
        amount_to_pay: checkoutCart.amount_to_pay,
      });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log('Stripe not loaded');
      return;
    }
    onSetOrdersLoading(true);
    onSetPageLoadingAction(true);
    const cardElement1 = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement1,
      billing_details: billingDetails,
    });
    if (error) {
      document.getElementById('card-errors').textContent = error.message;
      onSetOrdersLoading(false);
      onSetPageLoadingAction(false);
      console.log('[error]', error);
    } else {
      processOrder(paymentMethod);
    }
  };

  const handleFullNameChange = useCallback(
    e => {
      e.preventDefault();
      const newFullName = e?.target.value;

      setBillingDetails({ ...billingDetails, name: newFullName });
      correctnessOfPaymentDetails.name = Boolean(newFullName.replaceAll(' ', ''));
      setCorrectnessOfPaymentDetails({ ...correctnessOfPaymentDetails });
    },
    [billingDetails, correctnessOfPaymentDetails]
  );

  return (
    <Row className="mx-auto payment-card">
      <Col>
        <div className="mt-5 mx-auto custom">
          <Form.Group controlId="formBasicEmail1" className="w-100 mx-auto icons_login mb-3">
            <Form.Control
              size="md"
              type="text"
              placeholder={t('payment.nameOnTheCard')}
              className="cc-card card-number field"
              value={billingDetails.name}
              onChange={handleFullNameChange}
              required
            />
          </Form.Group>
          {stripe ? (
            <form onSubmit={handleSubmit}>
              <div id="card-number">
                <CardNumberElement options={cardNumberOptions} onChange={updateCardPaymentDetailsCorrectness} />
              </div>
              <div className="row expiry_cvv">
                <div id="card-expiry">
                  <CardExpiryElement options={cardExpiryOptions} onChange={updateCardPaymentDetailsCorrectness} />
                </div>
                <div id="card-cvv">
                  <CardCvcElement options={cardCVVOptions} onChange={updateCardPaymentDetailsCorrectness} />
                </div>
              </div>
              <div id="card-errors"></div>
              <Button id="pay-button" type="submit" disabled={!isPaymentDetailsCorrect}>
                {orderState.loading ? <Loaders.Common /> : null}
                Pay {checkoutCart?.currency} {checkoutCart?.amount_to_pay}
              </Button>
            </form>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default CheckoutForm;
