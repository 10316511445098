import React, { useEffect } from 'react';
// import "./conditions.scss";
import { Header } from '../../shared';
import { useTranslation } from 'react-i18next';

import { conditions } from './constants/conditions';
import { ConditionsList } from './components/ConditionsList';
import { ConditionsLinkList } from './components/ConditionsLinkList';

function ConditionsPage() {
  const { t } = useTranslation('conditions');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <div className="row my-4">
          <div className="col-md-8 t-col-left">
            <h1>{t('termsAndConditions')}</h1>
            <button type="button" className="btn btn-primary btn-sm">
              {t('lastUpdatedDate')}
            </button>

            <hr></hr>

            <article>
              <h3 id="conwelcome">{t('welcome')}</h3>
              <p className="mb-3">{t('welcome-1')}</p>
              <p className="mb-3">{t('welcome-2')}</p>
              <p className="mb-3">{t('welcome-3')}</p>
              <p className="mb-3">{t('welcome-4')}</p>
              <p className="mb-4">{t('welcome-5')}</p>
            </article>

            <article>
              <h3 id="conabout">{t('aboutOurSite')}</h3>
              <p className="mb-3">{t('aboutOurSite-1')}</p>
              <p className="mb-4">{t('aboutOurSite-2')}</p>
            </article>

            <ConditionsList conditions={conditions} />
          </div>

          <div className="col-md-4 t-col-right">
            <div className="sticky-side p-3">
              <p>{t('welcome')}</p>
              <ConditionsLinkList conditions={conditions} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ConditionsPage;
