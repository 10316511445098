import React from 'react';
import PropTypes from 'prop-types';

import { ConditionsLinkListItem } from './ConditionsLinkListItem';

export const ConditionsLinkList = ({ conditions }) => (
  <ul className="list-group list-group-flush ">
    {conditions?.map((condition, index) => (
      <ConditionsLinkListItem
        key={`condition-link-list-item-${index}-#${Math.random() * 1000}`}
        text={condition.title}
        href={condition.id}
      />
    ))}
  </ul>
);

ConditionsLinkList.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
