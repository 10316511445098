import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConditionsListItem } from './ConditionsListItem';

export const ConditionsList = ({ conditions }) => {
  const { t } = useTranslation('conditions');

  return conditions?.map((condition, index) => (
    <article key={`condition-${index + 1}-#${Math.random() * 1000}`}>
      <h3 id={condition.id}>
        {index + 1}. {t(condition.title)}
      </h3>
      {condition.list?.map((conditionListItem, listItemIndex) => {
        if (conditionListItem?.sublist)
          return (
            <p>
              <ul>
                {conditionListItem.sublist?.map(sublistItem => (
                  <ConditionsListItem
                    key={`condition-sublist-${condition.title}-${sublistItem.text}-item-${index}-${listItemIndex}-#${
                      Math.random() * 1000
                    }`}
                    text={sublistItem.text}
                    isSublistItem={true}
                    isLast={condition.list.length - 1 === listItemIndex}
                  />
                ))}
              </ul>
            </p>
          );
        if (conditionListItem?.dangerouslySetInnerHTML) {
          return (
            <ConditionsListItem
              key={`condition-list-${condition.title}-item-${index}-${listItemIndex}-#${Math.random() * 1000}`}
              text={conditionListItem.text}
              isLast={condition.list.length - 1 === listItemIndex}
              withDangerouslySetInnerHTML={true}
            />
          );
        } else {
          return (
            <ConditionsListItem
              key={`condition-list-${condition.title}-item-${index}-${listItemIndex}-#${Math.random() * 1000}`}
              text={conditionListItem.text}
              isLast={condition.list.length - 1 === listItemIndex}
              withDangerouslySetInnerHTML={true}
            />
          );
        }
      })}
    </article>
  ));
};
