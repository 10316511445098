import { api } from '../../../api';

export const getprofileAPI = () => {
  return api.get('/user');
};

export const updateprofileAPI = userData => {
  return api.put('/user', userData);
};

export const getPhoneLengthByCountryAPI = countryName => {
  return api.get('/countries/set_phone_attributes?country_name=' + countryName);
};
