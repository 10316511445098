import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ConditionsLinkListItem = ({ text, href }) => {
  const { t } = useTranslation('conditions');

  return (
    <li className="border-0 condition-item-style">
      <a href={href}>{t(text)}</a>
    </li>
  );
};

ConditionsLinkListItem.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
};
