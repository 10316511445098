export const conditions = [
  {
    id: 'coneligibilty',
    title: 'eligibility',
    list: [
      { text: 'eligibility-1.1' },
      { text: 'eligibility-1.1.1' },
      { text: 'eligibility-1.1.2' },
      { text: 'eligibility-1.2' },
      { text: 'eligibility-1.3' },
      { text: 'eligibility-1.4' },
    ],
  },
  {
    id: 'conobliation',
    title: 'obligations',
    list: [
      { text: 'obligations-2.1' },
      { text: 'obligations-2.1.1' },
      { text: 'obligations-2.1.2' },
      { text: 'obligations-2.1.3' },
      { text: 'obligations-2.1.4' },
      { text: 'obligations-2.1.5' },
      { text: 'obligations-2.2' },
      { text: 'obligations-2.2.1' },
      { text: 'obligations-2.2.2' },
      { text: 'obligations-2.2.3' },
      { text: 'obligations-2.2.4' },
      { text: 'obligations-2.2.5' },
      { text: 'obligations-2.2.6' },
      { text: 'obligations-2.2.7' },
      { text: 'obligations-2.2.8' },
      { text: 'obligations-2.2.9' },
      { text: 'obligations-2.2.10' },
      { text: 'obligations-2.2.11' },
      { text: 'obligations-2.2.12' },
      { text: 'obligations-2.2.13', dangerouslySetInnerHTML: true },
      { text: 'obligations-2.2.14' },
      { text: 'obligations-2.2.15' },
    ],
  },
  {
    id: 'conintellectual',
    title: 'intellectualPropertyRights',
    list: [
      { text: 'intellectualPropertyRights-3.1' },
      { text: 'intellectualPropertyRights-3.1.1' },
      { text: 'intellectualPropertyRights-3.1.2' },
      { text: 'intellectualPropertyRights-3.2' },
      { text: 'intellectualPropertyRights-3.3' },
    ],
  },
  {
    id: 'conwarranties',
    title: 'warranties',
    list: [
      { text: 'warranties-4.1' },
      { text: 'warranties-4.1.1' },
      {
        text: 'warranties-4.1.2',
        sublist: [{ text: 'warranties-4.1.2-point-1' }, { text: 'warranties-4.1.2-point-2' }],
      },
      { text: 'warranties-4.1.3' },
      { text: 'warranties-4.2' },
      { text: 'warranties-4.3' },
    ],
  },
  {
    id: 'conliability',
    title: 'liabilityAndIndemnities',
    list: [
      { text: 'liabilityAndIndemnities-5.1' },
      { text: 'liabilityAndIndemnities-5.1.1' },
      { text: 'liabilityAndIndemnities-5.1.2' },
      { text: 'liabilityAndIndemnities-5.1.3' },
      { text: 'liabilityAndIndemnities-5.2' },
      { text: 'liabilityAndIndemnities-5.3' },
      { text: 'liabilityAndIndemnities-5.3.1' },
      { text: 'liabilityAndIndemnities-5.2' },
      { text: 'liabilityAndIndemnities-5.3' },
      { text: 'liabilityAndIndemnities-5.3.1' },
      { text: 'liabilityAndIndemnities-5.3.2' },
      { text: 'liabilityAndIndemnities-5.3.3' },
      { text: 'liabilityAndIndemnities-5.3.4' },
      { text: 'liabilityAndIndemnities-5.3.5' },
      { text: 'liabilityAndIndemnities-5.3.6' },
      { text: 'liabilityAndIndemnities-5.3.7' },
      { text: 'liabilityAndIndemnities-5.3.8' },
      { text: 'liabilityAndIndemnities-5.3.9' },
      { text: 'liabilityAndIndemnities-5.4' },
      { text: 'liabilityAndIndemnities-5.4.1' },
      { text: 'liabilityAndIndemnities-5.4.2' },
      { text: 'liabilityAndIndemnities-5.5' },
      { text: 'liabilityAndIndemnities-5.5.1' },
      { text: 'liabilityAndIndemnities-5.5.2' },
      { text: 'liabilityAndIndemnities-5.5.3' },
      { text: 'liabilityAndIndemnities-5.5.4' },
    ],
  },
  {
    id: 'consuspension',
    title: 'suspension',
    list: [{ text: 'suspension-6.1' }],
  },
  {
    id: 'conreporting',
    title: 'reportingViolations',
    list: [{ text: 'reportingViolations-7.1' }],
  },
  {
    id: 'conaffiliates',
    title: 'affiliates',
    list: [{ text: 'affiliates-8.1', dangerouslySetInnerHTML: true }, { text: 'affiliates-8.2' }],
  },
  {
    id: 'congift',
    title: 'giftCardInformation',
    list: [
      { text: 'giftCardInformation-9.1' },
      { text: 'giftCardInformation-9.2' },
      { text: 'giftCardInformation-9.3' },
    ],
  },
  {
    id: 'conpayments',
    title: 'paymentMethods',
    list: [{ text: 'paymentMethods-10.1' }, { text: 'paymentMethods-10.2' }, { text: 'paymentMethods-10.3' }],
  },
  {
    id: 'confulfil',
    title: 'fulfillment',
    list: [{ text: 'fulfillment-11.1' }],
  },
  {
    id: 'congeneral',
    title: 'general',
    list: [
      { text: 'general-12.1', dangerouslySetInnerHTML: true },
      { text: 'general-12.2', dangerouslySetInnerHTML: true },
      { text: 'general-12.3', dangerouslySetInnerHTML: true },
      { text: 'general-12.4', dangerouslySetInnerHTML: true },
      { text: 'general-12.5', dangerouslySetInnerHTML: true },
      { text: 'general-12.6', dangerouslySetInnerHTML: true },
      { text: 'general-12.7', dangerouslySetInnerHTML: true },
      { text: 'general-12.8', dangerouslySetInnerHTML: true },
      { text: 'general-12.9', dangerouslySetInnerHTML: true },
      { text: 'general-12.10', dangerouslySetInnerHTML: true },
      { text: 'general-12.11', dangerouslySetInnerHTML: true },
      { text: 'general-12.12', dangerouslySetInnerHTML: true },
      { text: 'general-12.13', dangerouslySetInnerHTML: true },
    ],
  },
];
