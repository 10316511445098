import { api } from '../../../api';
export const descriptionBrand = brands => {
  const { id, currency, image_size, image_type, program_id } = brands;
  return api.get(`/brands/${id}`, {
    params: {
      currency,
      image_size,
      image_type,
      program_id,
    },
  });
};
export const termBrand = brands => {
  const { id, currency } = brands;
  return api.get(`/brands/${id}/terms`, { params: { currency } });
};

// export const product_description = (brands) => {
//     const {id, currency, program_id} = brands;
//     const url = `${API_URL}/brands/451/product_description?currency=${currency}&&id=${id}&&program_id=${program_id}`;
//     return apiCall(url, 'GET', null, null, false);
// }

export const featured_brands = brands => {
  const { program_id, currency } = brands;
  return api.get('/brands/featured_brands', {
    params: {
      currency,
      program_id,
    },
  });
};

export const brands_by_category = brands => {
  const { program_id, currency, category_id } = brands;
  return api.get('/brands/brands_by_category', {
    params: {
      category_id,
      currency,
      program_id,
    },
  });
};

export const allBrand = brands => {
  const { currency, image_size, image_type, list_type, program_id } = brands;
  return api.get('/brands', {
    params: {
      currency,
      image_size,
      list_type,
      image_type,
      program_id,
    },
  });
};
