import {
  cartItemAction,
  fetchItemsByCartAction,
  addRemoveQuantityAction,
  getConversionRateAction,
  getFixerConversionRateAction,
  getPaymentCurrencyAction,
  updateCartAction,
} from './actions';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { logoutAction } from '../../auth/redux/auth.actions';

export const CART_ITEMS_INIT_STATE = {
  message: '',
  errors: '',
  cart_items: [],
  gift_message: '',
  country_name: '',
  count: 1,
  lineItems: [],
  totalCartItems: 0,
  selectedCartCurrency: null,
  paymentCurrency: [],
  conversion: null,
  totalCartAmount: 0,
  checkoutCart: null,
};

export const CART_ITEMS_REDUCER = 'cart_items';
export const cartItemsAdaptor = createEntityAdapter();
export const initialCartItemState = cartItemsAdaptor.getInitialState(CART_ITEMS_INIT_STATE);

export const cartItemsSlice = createSlice({
  name: CART_ITEMS_REDUCER,
  initialState: initialCartItemState,
  reducers: {
    saveItemsToCart(state, action) {
      state.lineItems = action.payload;
    },
    updateSelectedCartCurrency(state, action) {
      state.selectedCartCurrency = action.payload;
    },
    updateTotalCartItems(state, action) {
      state.totalCartItems = action.payload;
    },
    updateCheckout(state, action) {
      state.checkoutCart = action.payload;
    },
    clearState(state) {
      state.checkoutCart = null;
      state.selectedCartCurrency = null;
      state.conversion = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(cartItemAction.pending, state => {
        state.errros = null;
        // state.cart_items = null;
      })
      .addCase(cartItemAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (200 === code) {
          state.cart_items = data;
        }
      })
      .addCase(cartItemAction.rejected, (state, action) => {
        state.errros = [action.error.message || ''];
      })
      .addCase(updateCartAction.pending, state => {
        state.errros = null;
      })
      .addCase(updateCartAction.fulfilled, (state, action) => {
        const response = action.payload;
        const { data, code } = response;
        if (code === 200 && data?.cart_item) {
          state.lineItems = [...state.lineItems, data?.cart_item];
        }
      })
      .addCase(updateCartAction.rejected, (state, action) => {
        state.errros = [action.error.message || ''];
      })
      .addCase(fetchItemsByCartAction.pending, state => {
        state.errors = null;
        // state.lineItems = [];
      })
      .addCase(fetchItemsByCartAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (code === 200) {
          state.lineItems = data.carts;
          state.totalCartAmount = parseFloat(data.total_price_in_aed).toFixed(2);
          state.totalCartItems = data.carts?.length || 0;
        } else {
          state.lineItems = [];
        }
      })
      .addCase(fetchItemsByCartAction.rejected, (state, action) => {
        state.errros = [action.error.message || ''];
        state.lineItems = [];
      })
      .addCase(addRemoveQuantityAction.pending, state => {
        state.errors = null;
        // state.add_or_remove_quantity = null;
      })
      .addCase(addRemoveQuantityAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (200 === code) {
          state.add_or_remove_quantity = data;
        }
      })
      .addCase(addRemoveQuantityAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      // Payment CUrrency Action
      .addCase(getPaymentCurrencyAction.pending, state => {
        state.errors = null;
        state.paymentCurrency = [];
      })
      .addCase(getPaymentCurrencyAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (200 === code) {
          state.paymentCurrency = data?.currencies || [];
          state.selectedCartCurrency = data?.currencies[0] || null;
        }
      })
      .addCase(getPaymentCurrencyAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(getConversionRateAction.pending, state => {
        state.errors = null;
        state.conversion = null;
      })
      .addCase(getConversionRateAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (200 === code) {
          state.conversion = data;
          state.selectedCartCurrency = data;
        }
      })
      .addCase(getConversionRateAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(getFixerConversionRateAction.pending, state => {
        state.conversion = null;
      })
      .addCase(getFixerConversionRateAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (code === 200) {
          const { id, currency_exchange_rate, point_conversion_rate, unit_name_short, minimum_payable_amount } = data;
          state.conversion = {
            currency_exchange_rate,
            point_conversion_rate,
            minimum_payable_amount,
          };
          state.selectedCartCurrency = {
            id,
            unit_name_short,
          };
        }
      })
      .addCase(getFixerConversionRateAction.rejected, state => {
        state.conversion = null;
      })
      .addCase(logoutAction.fulfilled, state => {
        state = CART_ITEMS_INIT_STATE;
        return state;
      });
  },
});

export const cartItemReducer = cartItemsSlice.reducer;
export const cartAction = cartItemsSlice.actions;
export const { selectAll, selectEntities } = cartItemsAdaptor.getInitialState();
export const getCartItemsState = rootState => rootState[CART_ITEMS_REDUCER];
export const selectAllCartItems = createSelector(getCartItemsState, selectAll);
export const selectCartItemsEntities = createSelector(getCartItemsState, selectEntities);
