import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';
import { cartItemsService, fetchItemsByCartService, addRemoveQuantityService, getFixerConversionRateAPI } from '../api';
import { getConversionRateService, getPaymentCurrencyService } from '../../cards/api';

export const cartItemAction = createAsyncThunk('cart_items/listcartItem', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    cart_item: {
      brand_id: payload.brand_id,
      quantity: payload.quantity,
      currency: payload.currency,
      giftcard_value: payload.giftcard_value,
      card_value_aed: payload.card_value_aed,
      isforself: payload.isforself,
      country_id: payload.country_id,
    },
  };
  const response = await cartItemsService(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const fetchItemsByCartAction = createAsyncThunk('cart_items/listfetchcart', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    currency: payload.currency,
    currency_id: payload.currency_id,
  };
  const response = await fetchItemsByCartService(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const addRemoveQuantityAction = createAsyncThunk('cart_items/listaddremove', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    cart_item: {
      brand_name: payload.product_name,
      quantity: payload.quantity,
      giftcard_value: payload.giftcard_value,
      currency: payload.currency,
      action: payload.action,
    },
  };
  const response = await addRemoveQuantityService(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  dispatch(
    fetchItemsByCartAction({
      currency: payload.country_unit_short_name,
      currency_id: payload.country_id,
    })
  );
  return response;
});

export const updateCartAction = createAsyncThunk('cart_items/update', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    cart_item: {
      brand_id: payload.brand_id,
      quantity: payload.quantity,
      currency: payload.currency,
      giftcard_value: payload.giftcard_value,
      card_value_aed: payload.card_value_aed,
      isforself: payload.isforself,
      country_id: payload.country_id,
    },
  };
  if (!request.cart_item.isforself) {
    request.cart_item['contact_email'] = payload.contact_email;
    request.cart_item['contact_name'] = payload.contact_name;
    request.cart_item['gift_message'] = payload.gift_message;
    request.cart_item['gifting_image_id'] = payload.gifting_image_id;
  }
  const response = await cartItemsService(request);
  if (response.code === 200) {
    dispatch(
      fetchItemsByCartAction({
        currency: 'AED',
        currency_id: 1,
      })
    );
  }

  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const bulkUpdateCartAction = createAsyncThunk('cart_items/bulk_update', async (_, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const state = getState();
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  let response = Promise.resolve()
  if (state.cart_items.totalCartItems) {
    const itemsInTempCart = state.cart_items.lineItems.map(item => ({
      cart_item: {
        brand_id: item.brand_id,
        quantity: item.quantity,
        currency: item.currency,
        giftcard_value: item.giftcard_value,
        card_value_aed: item.card_value_aed,
        isforself: item.isforself,
        country_id: item.country_id,
      }
    }));

    response = await Promise.all(itemsInTempCart.map(item => cartItemsService(item)));
  }

  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const getPaymentCurrencyAction = createAsyncThunk(
  'cart_items/listPaymentCurrency',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(pageLoaderActions.setPageLoadingAction(true));
    const response = await getPaymentCurrencyService();
    if (response?.code === 200) {
      await dispatch(getFixerConversionRateAction(response.data.currencies[0]));
    }
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return response;
  }
);

export const getConversionRateAction = createAsyncThunk(
  'cart_items/listPaymentConversions',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(pageLoaderActions.setPageLoadingAction(true));
    const request = {
      currency: payload.id,
    };
    //todo: fix dispatching of action
    //dispatch(cartAction.updateSelectedCartCurrency(payload));
    const response = await getConversionRateService(request);
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return response;
  }
);

export const getFixerConversionRateAction = createAsyncThunk(
  'cart_items/fixer/conversionrate',
  async (payload, thunkAPI) => {
    const currency = payload?.unit_name_short;
    const { dispatch } = thunkAPI;
    dispatch(pageLoaderActions.setPageLoadingAction(true));
    const { data, ...restResponse } = await getFixerConversionRateAPI(currency);
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return { data: { ...data, ...payload }, ...restResponse };
  }
);
